// react
import { forwardRef } from 'react'
// next
import Head from 'next/head'
// @mui
import { Box } from '@mui/material'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {string} props.title
 * @param {string} props.description
 * @param {boolean} [props.isEnableTitlePostfix]
 * @returns {JSX.Element}
 */
const Page = forwardRef(
  (
    { children, title, description, isEnableTitlePostfix = true, ...other },
    ref
  ) => {
    const appliedTitle =
      (isEnableTitlePostfix ? `${title} | MySkill` : title) ?? 'MySkill'

    return (
      <>
        <Head>
          <title>{appliedTitle}</title>

          {description && (
            <meta
              name='description'
              content={description}
            />
          )}
        </Head>

        <Box
          ref={ref}
          {...other}
        >
          {children}
        </Box>
      </>
    )
  }
)

Page.displayName = 'Page'

export default Page
