// react
import { Children, useCallback, useEffect, useMemo, useState } from 'react'
// @mui
import { Box, Grid, Stack, ButtonBase } from '@mui/material'
import { ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

// ----------------------------------------------------------------------

const ScrollGridContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'scrollSnapAlign'
})(({ scrollSnapAlign }) => ({
  flexWrap: 'nowrap',
  overflowX: 'scroll',
  // scroll snapping
  scrollSnapType: 'x mandatory',
  '& > *': { scrollSnapAlign },
  // hide scrollbar
  msOverflowStyle: 'none' /* IE and Edge */,
  scrollbarWidth: 'none' /* Firefox */,
  '::-webkit-scrollbar': { display: 'none' },
  scrollBehavior: 'smooth'
}))

const EdgeNav = styled(ButtonBase, {
  shouldForwardProp: (prop) => !['direction', 'isFullHeight'].includes(prop)
})(({ theme, direction, isFullHeight = false }) => ({
  position: 'absolute',
  top: isFullHeight ? 0 : 'min(2rem, 25%)',
  bottom: isFullHeight ? 0 : 'min(2rem, 25%)',
  zIndex: theme.zIndex.fab,
  width: '2rem',
  height: isFullHeight ? '100%' : 'calc(100% - min(4rem, 50%))',
  borderRadius:
    direction === 'left'
      ? '0 1rem 1rem 0'
      : direction === 'right'
      ? '1rem 0 0 1rem'
      : '1rem',
  backgroundColor: 'transparent',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  transition: theme.transitions.create('opacity,background-color'),
  '&:hover': { backgroundColor: theme.palette.grey[500_48] },
  // inner button
  '& > *': { opacity: 0 },
  '&:hover > *': { opacity: 0.55 }
}))

const EdgeNavArrow = styled('span')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})

export const DotNav = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'isActive'
})(({ isActive, theme }) => ({
  width: isActive ? '0.8em' : '0.4rem',
  height: '0.4rem',
  borderRadius: '0.4rem',
  backgroundColor: theme.palette.grey[500],
  opacity: isActive ? 1 : 0.5,
  cursor: 'pointer',
  transformOrigin: 'center',
  transition: [
    theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.standard
    })
  ],
  [theme.breakpoints.up('md')]: {
    width: isActive ? '1.2rem' : '0.6rem',
    height: '0.6rem',
    borderRadius: '0.6rem'
  }
}))

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {number | Object | string} [props.columns=1]
 * @param {number | Object | string} [props.spacing=1]
 * @param {number | string} [props.peek=0]
 * @param {boolean} [props.isEnableEdgeNav=true]
 * @param {boolean} [props.isEnableEdgeNavArrow=true]
 * @param {boolean} [props.isEnableEdgeNavFullHeight=false]
 * @param {boolean} [props.isEnableDotNav=false]
 * @param {boolean} [props.isPreserveScroll=false]
 * @param {number} [props.initialItem=0]
 * @param {'start' | 'center' | 'end'} [props.scrollSnapAlign='left']
 * @param {import('@mui/material').SxProps<theme>} [props.sx={}]
 * @returns {JSX.Element}
 */
export default function GridCarousel({
  children,
  columns = 1,
  spacing = 1,
  peek = 0, // range 0 - 0.5
  isEnableEdgeNav = true,
  isEnableEdgeNavArrow = true,
  isEnableEdgeNavFullHeight = false,
  isEnableDotNav = false,
  isPreserveScroll = false,
  initialItem = 0,
  scrollSnapAlign = 'left',
  sx = {},
  ...rest
}) {
  // make sure children is array
  const arrayChildren = useMemo(() => Children.toArray(children), [children])

  // refs handler
  const [containerRef, setContainerRef] = useState(null)
  const [itemRefs, setItemRefs] = useState({})

  const syncContainerRef = useCallback((ref) => {
    setContainerRef(ref)
  }, [])

  const updateItemRefs = useMemo(
    () =>
      arrayChildren.reduce((acc, curr) => {
        const updateRef = (ref) => {
          if (!ref) {
            setItemRefs((prev) => {
              const { [curr.key]: _, ...itemRefRest } = prev

              return itemRefRest
            })
            return
          }
          setItemRefs((prev) => ({ ...prev, [curr.key]: ref }))
        }

        return { ...acc, [curr.key]: updateRef }
      }, {}),
    [arrayChildren]
  )

  // container intersection observer to track visible items
  const [visibleItems, setVisibleItems] = useState({})
  // browser support override
  const [isBrowserSupport, setIsBrowserSupport] = useState(false)

  useEffect(() => {
    // client side only, and only when container is ready
    if (typeof document === 'undefined' || !containerRef) return () => null

    // check if browser support intersection observer, otherwise skip
    if (!('IntersectionObserver' in window)) {
      return () => null
    } else {
      setIsBrowserSupport(true)
    }

    if (!isEnableEdgeNav && !isEnableDotNav)
      // if no nav is enabled, skip intersection observer
      return () => null

    // if only has less children than columns,
    // or if it has 1 children or less
    // skip intersection observer
    const itemNo = Object.keys(itemRefs).length

    if ((typeof columns === 'number' && itemNo <= columns) || itemNo <= 1)
      return () => null

    const observerOptions = {
      root: containerRef,
      threshold: [0.5]
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const itemIndex = entry.target.dataset.key

        setVisibleItems((vi) => {
          if (entry.intersectionRatio > 0.5) {
            return { ...vi, [itemIndex]: true }
          } else {
            return { ...vi, [itemIndex]: false }
          }
        })
      })
    }, observerOptions)

    Object.values(itemRefs).forEach((ir) => {
      if (ir) observer.observe(ir)
    })

    return () => {
      if (observer) observer.disconnect()
    }
  }, [columns, isEnableDotNav, isEnableEdgeNav, containerRef, itemRefs])

  // make sure container is scrolled to initialSlide when children change
  // default initialSlide is 0 (fully scrolled to left)
  // except when isPreserveScroll is true
  useEffect(() => {
    if (!containerRef) return

    if (isPreserveScroll) return

    if (initialItem === 0) {
      containerRef.scrollLeft = 0

      return
    }

    containerRef.scrollLeft = containerRef.scrollLeft =
      itemRefs[initialItem]?.offsetLeft ?? 0
  }, [containerRef, itemRefs, initialItem, isPreserveScroll])

  // children base styling
  const childrenBaseSx = useMemo(() => {
    if (!peek) return {}

    if (!columns) {
      return {}
    }

    // columns is a numberish
    const columnsValue = parseInt(columns)
    if (columnsValue)
      return {
        flexBasis: `calc(${100 - peek * 100}% / ${columns}) !important`,
        maxWidth: `calc(${100 - peek * 100}% / ${columns}) !important`
      }

    // column is a responsive value object
    const sizingValue = Object.entries(columns).reduce(
      (acc, [breakpoint, value]) => ({
        ...acc,
        [breakpoint]: `calc(${100 - peek * 100}% / ${value}) !important`
      }),
      {}
    )

    return {
      flexBasis: sizingValue,
      maxWidth: sizingValue
    }
  }, [peek, columns])

  // dot navigation
  const createItemViewportNavigationClickHandler = (index) => () => {
    containerRef.scrollLeft = itemRefs[index]?.offsetLeft
  }

  // edge/arrow navigation
  const handleNext = () => {
    if (!containerRef) return

    const widthWithoutPadding = containerRef.clientWidth

    containerRef.scrollLeft += widthWithoutPadding * (1 - peek)
  }

  const handlePrev = () => {
    if (!containerRef) return

    const widthWithoutPadding = containerRef.clientWidth

    containerRef.scrollLeft -= widthWithoutPadding * (1 - peek)
  }

  // sprinkle some magic...
  // basically just decide to show edege nav or not
  // based on current visible items
  const isNavigateable =
    (typeof columns !== 'number' || arrayChildren.length > columns) &&
    arrayChildren.length > 1
  const showEdgeNavPrev =
    isNavigateable && visibleItems[arrayChildren[0].key] === false
  const showEdgeNavNext =
    isNavigateable &&
    visibleItems[arrayChildren[arrayChildren.length - 1].key] === false

  return (
    <div>
      <Box sx={{ position: 'relative', overflow: 'hidden', ...(sx ?? {}) }}>
        <ScrollGridContainer
          ref={syncContainerRef}
          //
          container
          columns={columns === 'auto' ? undefined : columns}
          columnSpacing={spacing}
          //
          scrollSnapAlign={scrollSnapAlign}
          //
          {...rest}
        >
          {arrayChildren.map(({ key, ...child }) => (
            <Grid
              ref={updateItemRefs[key]}
              //
              key={`item-${key}`}
              data-key={key}
              //
              item
              xs={columns === 'auto' ? 'auto' : 1}
              //
              sx={{ flexShrink: 0, ...childrenBaseSx }}
            >
              {child}
            </Grid>
          ))}
        </ScrollGridContainer>

        {isEnableEdgeNav && (
          <>
            {showEdgeNavPrev && (
              <EdgeNav
                direction='left'
                isFullHeight={isEnableEdgeNavFullHeight}
                onClick={handlePrev}
                sx={{ left: 0 }}
              >
                {isEnableEdgeNavArrow && (
                  <EdgeNavArrow>
                    <ChevronLeftOutlined />
                  </EdgeNavArrow>
                )}
              </EdgeNav>
            )}

            {showEdgeNavNext && (
              <EdgeNav
                direction='right'
                isFullHeight={isEnableEdgeNavFullHeight}
                onClick={handleNext}
                sx={{ right: 0 }}
              >
                {isEnableEdgeNavArrow && (
                  <EdgeNavArrow>
                    <ChevronRightOutlined />
                  </EdgeNavArrow>
                )}
              </EdgeNav>
            )}
          </>
        )}
      </Box>

      {
        // Browser must support IntersectionObserver for dot nav to work
        isBrowserSupport && isEnableDotNav && (
          <Stack
            direction='row'
            justifyContent='flex-end'
            spacing={{ xs: 0.4, md: 0.6 }}
            marginTop={1}
          >
            {arrayChildren.map(({ key }) => (
              <DotNav
                key={`dot-nav-${key}`}
                aria-label='jump to item'
                onClick={createItemViewportNavigationClickHandler(key)}
                isActive={visibleItems[key]}
              />
            ))}
          </Stack>
        )
      }
    </div>
  )
}
