// @mui
import { Container } from '@mui/material'
import { styled } from '@mui/material/styles'

// ----------------------------------------------------------------------

const SIZE_MAP = Object.freeze({
  sm: 2,
  md: 4
})

/**
 * @typedef {Object} SectionProps
 * @property {keyof typeof SIZE_MAP} [size]
 * @extends {import('@mui/material').ContainerProps}
 *
 * @param {SectionProps} props
 * @returns {JSX.Element}
 */
export const Section = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'size'
})(({ size, theme }) => ({
  paddingTop: theme.spacing(SIZE_MAP[size] ?? SIZE_MAP.md),
  paddingBottom: theme.spacing(SIZE_MAP[size] ?? SIZE_MAP.md)
}))
