// react
import { useMemo } from 'react'
// @mui
import { Button, Card, CardContent } from '@mui/material'
// components
import GridCarousel from '~/components/GridCarousel'
import NextImage from '~/components/NextImage'
// hooks
import useResponsive from '~/hooks/useResponsive'
// constants
import {
  BOOTCAMP_TESTIMONY,
  ELEARNING_TESIMONY,
  GENERAL_PRODUCT_TESTIMONY,
  MENTORING_CAREER_TESTIMONY,
  REVIEW_CV_TESTIMONY
} from '~/constants/testimony/product'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {'e-learning'|'bootcamp'|'mentoring/review'|'mentoring/career'} props.type
 * @param {boolean} [props.isPriorityImage]
 * @param {number} [props.limit]
 * @returns {JSX.Element}
 */
export default function MemberTestimony({
  type,
  limit,
  isPriorityImage = false
}) {
  const appliedTestimonyList = useMemo(() => {
    let atl = GENERAL_PRODUCT_TESTIMONY
    if (type === 'e-learning') atl = ELEARNING_TESIMONY
    if (type === 'bootcamp') atl = BOOTCAMP_TESTIMONY
    if (type === 'mentoring/review') atl = REVIEW_CV_TESTIMONY
    if (type === 'mentoring/career') atl = MENTORING_CAREER_TESTIMONY

    if (limit) atl = atl.slice(0, limit)

    return atl
  }, [type, limit])

  const mdUp = useResponsive('up', 'md')

  return (
    <GridCarousel
      columns={{ xs: 2, sm: 3, md: 5 }}
      peek={
        mdUp
          ? appliedTestimonyList.length > 5 && 0.05
          : appliedTestimonyList.length > 2 && 0.15
      }
      spacing={{ xs: 1, md: 2 }}
    >
      {appliedTestimonyList.map(({ alt, storyURL, image }) => (
        <Card key={storyURL}>
          <CardContent
            sx={{
              paddingX: { xs: 1, md: 2 },
              paddingTop: { xs: 1, md: 2 },
              paddingBottom: { xs: '0.5rem !important', md: '1rem !important' }
            }}
          >
            <NextImage
              src={image}
              alt={alt}
              priority={isPriorityImage}
              nextHeight={180}
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: 1,
                mb: { xs: 1, md: 2 }
              }}
            />

            <Button
              LinkComponent='a'
              href={storyURL}
              target='_blank'
              rel='noopener noreferrer'
              variant='contained'
              fullWidth
              size={mdUp ? 'medium' : 'small'}
            >
              Baca Cerita
            </Button>
          </CardContent>
        </Card>
      ))}
    </GridCarousel>
  )
}
